import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>Monitor 11 - {title}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Monitor 11",
  keyword:
    "website monitoring, api monitor, status page",
  desc: "Test And Monitor Every Aspect Of Your Website To Ensure Maximum Availability",
};

export default Meta;
