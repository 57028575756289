const footerMenuList = [
  // {
  //   id: 1,
  //   title: "Platform",
  //   diffClass: "md:col-start-7",
  //   list: [
  //     {
  //       id: 1,
  //       href: "#",
  //       text: "Website Monitor",
  //     },
  //     {
  //       id: 2,
  //       href: "#",
  //       text: "API Monitor",
  //     },
  //     {
  //       id: 3,
  //       href: "#",
  //       text: "Status Page Builder",
  //     }
  //   ],
  // },
  // {
  //   id: 2,
  //   title: "Resources",
  //   diffClass: "",
  //   list: [
  //     {
  //       id: 1,
  //       href: "#",
  //       text: "Help Center",
  //     },
  //     {
  //       id: 2,
  //       href: "#",
  //       text: "Platform Status",
  //     },
  //     {
  //       id: 3,
  //       href: "#",
  //       text: "Blog",
  //     }
  //   ],
  // },
  // {
  //   id: 3,
  //   title: "Company",
  //   diffClass: "",
  //   list: [
  //     {
  //       id: 1,
  //       href: "#",
  //       text: "Work at Monitor11",
  //     },
  //     {
  //       id: 2,
  //       href: "#",
  //       text: "About Us",
  //     }
  //   ],
  // },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://www.twitter.com",
    text: "twitter",
  },
  {
    id: 3,
    href: "https://www.discord.com",
    text: "discord",
  },
  {
    id: 4,
    href: "https://www.instagram.com",
    text: "instagram",
  },
  {
    id: 5,
    href: "https://www.tiktok.com",
    text: "tiktok",
  },
];

export { footerMenuList, socialIcons };
